<template>
    <div class="row-head-center">
        <div class="row-head">
            <div class="mr-2">{{ title }}</div>
            <div style="display: flex;flex-direction: column;cursor: pointer;"
                v-if="!checkOrder('asc') && !checkOrder('desc')" v-on:click="orderByColumn('asc')">
                <i class="fas fa-caret-up" style="height: 8px; color: lightgray;"></i>
                <i class="fas fa-caret-down" style="height: 8px; color: lightgray;"></i>
            </div>
            <div style="display: flex;flex-direction: column;cursor: pointer;" v-if="checkOrder('asc')"
                v-on:click="orderByColumn('desc')">
                <i class="fas fa-caret-up" style="height: 8px; color: lightgray"></i>
                <i class="fas fa-caret-down" style="height: 8px; color: white;"></i>
            </div>
            <div style="display: flex;flex-direction: column;cursor: pointer;" v-if="checkOrder('desc')"
                v-on:click="orderByColumn('asc')">
                <i class="fas fa-caret-up" style="height: 8px; color: white;"></i>
                <i class="fas fa-caret-down" style="height: 8px; color: lightgray;"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        column: String,
        orders: [],
        callBack: {
            type: Function
        }
    },
    data() {
        return {
            itemOrders: [],
        };
    },
    methods: {
        checkOrder(type) {
            if (this.column && type) {
                let items = this.itemOrders.filter((item) => {
                    return item.column === this.column && item.type === type;
                })
                if (items.length == 0) return false;
            }
            return true;
        },
        orderByColumn(type) {
            this.itemOrders = [];
            this.itemOrders.push({ column: this.column, type: type });
            this.callBack(this.itemOrders);
        }
    },
    mounted() {
        this.itemOrders = this.orders;
    },
    watch: {
        orders() {
            this.itemOrders = this.orders;
        }
    }
};
</script>